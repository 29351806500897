import { BackgroundContainer } from './styles';

const Background = () => {
  return (
    <BackgroundContainer>
    
    </BackgroundContainer>
  );
};

export default Background;
